import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "superior" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "superior-elcyklar---utforska-våra-premium-elcykelserier"
    }}>{`Superior Elcyklar - Utforska Våra Premium Elcykelserier`}</h1>
    <p>{`Välkommen till vår brand-sida för `}<strong parentName="p">{`Superior elcyklar`}</strong>{`. Här hittar du en översikt av våra högkvalitativa och innovativa elcyklar som är designade för att erbjuda en enastående cykelupplevelse. Varje serie av `}<strong parentName="p">{`Superior elcyklar`}</strong>{` är speciellt framtagen för olika typer av cykeläventyr, från daglig pendling i stadsmiljö till längre utflykter på landsväg och terräng. Läs vidare för att upptäcka vilken serie som bäst passar dina behov och förväntningar.`}</p>
    <h2 {...{
      "id": "alla-våra-superior-elcykelserier"
    }}>{`Alla Våra Superior Elcykelserier`}</h2>
    <h3 {...{
      "id": "superior-exr-series-för-utforskning-och-äventyr"
    }}>{`Superior EXR-Series: För Utforskning och Äventyr`}</h3>
    <p><strong parentName="p">{`Superior EXR-serien`}</strong>{` är framtagen för den äventyrslystna cyklisten som vill kombinera komfort med kraftfull prestanda. Cyklarna i denna serie är utrustade med motorer från Bosch och Shimano, kända för sin effektivitet och hållbarhet. Serien erbjuder flera underkategorier:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Superior EXR 6090 B LS Touring`}</strong>{`:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Bosch Performance Line Cruise-motor`}</strong>{` för dynamisk acceleration.`}</li>
          <li parentName="ul"><strong parentName="li">{`12-växlad Shimano Deore`}</strong>{` för smidig växling.`}</li>
          <li parentName="ul">{`Upp till `}<strong parentName="li">{`182 km räckvidd`}</strong>{`.`}</li>
          <li parentName="ul">{`Bekväm låginstegsdesign.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Superior EXR 6090 B L Touring`}</strong>{`:`}
        <ul parentName="li">
          <li parentName="ul">{`Kraftfull Bosch Performance Line Cruise-motor.`}</li>
          <li parentName="ul">{`Integrerat 625Wh batteri för lång räckvidd.`}</li>
          <li parentName="ul">{`Robust aluminiumram och RockShox dämpningssystem.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Superior EXR 6050 B LS Touring`}</strong>{`:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Bosch Active Line Plus motor`}</strong>{` och 500Wh batteri.`}</li>
          <li parentName="ul">{`9-växlad Shimano Alivio-drivlina.`}</li>
          <li parentName="ul">{`Hydrauliska skivbromsar och RST Volant framgaffel.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Superior EXR 6050 B L Touring`}</strong>{`:`}
        <ul parentName="li">
          <li parentName="ul">{`För både stadsmiljö och terräng med hög prestanda.`}</li>
          <li parentName="ul">{`Bosch Active Line Plus motor och långvarigt 500Wh batteri.`}</li>
          <li parentName="ul">{`9-växlad Shimano Alivio drivlina och robust aluminiumram.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "superior-sbt-series-urban-mobilitet-med-hög-prestanda"
    }}>{`Superior SBT-Series: Urban Mobilitet med Hög Prestanda`}</h3>
    <p><strong parentName="p">{`Superior SBT-serien`}</strong>{` är idealisk för den moderna pendlaren som söker en elcykel med ett starkt fokus på stadskörning. Cyklarna i denna serie är både eleganta och kraftfulla, utrustade med avancerad teknologi för att möta dina dagliga behov:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Superior SBT 300 L`}</strong>{`:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Bosch motor, Active Line Plus`}</strong>{` för kraftfull och tyst drift.`}</li>
          <li parentName="ul">{`Räckvidd upp till `}<strong parentName="li">{`140 km`}</strong>{` per laddning.`}</li>
          <li parentName="ul">{`10-växlad Shimano Deore-drivlina.`}</li>
          <li parentName="ul">{`Hydrauliska skivbromsar och dämpad framgaffel från Suntour.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "superior-ssc-series-kombinera-stil-och-funktionalitet"
    }}>{`Superior SSC-Series: Kombinera Stil och Funktionalitet`}</h3>
    <p>{`Cyklarna i `}<strong parentName="p">{`Superior SSC-serien`}</strong>{` är designade för att balansera stil och funktionalitet. Dessa cyklar är perfekta för både korta och långa turer, med särskilt fokus på komfort och användarvänlighet:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Superior SSC 100L`}</strong>{`:`}
        <ul parentName="li">
          <li parentName="ul">{`Shimano Steps motor och 7-växlad Shimano Nexus drivlina.`}</li>
          <li parentName="ul">{`Räckvidd upp till `}<strong parentName="li">{`130 km`}</strong>{`.`}</li>
          <li parentName="ul">{`Elegant aluminiumram och ergonomisk design.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Superior SSC 160 L`}</strong>{`:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Shimano Steps E6100-motor`}</strong>{` och 504Wh batteri.`}</li>
          <li parentName="ul">{`Räckvidd upp till `}<strong parentName="li">{`185 km`}</strong>{`.`}</li>
          <li parentName="ul">{`7-växlad Shimano Nexus-system.`}</li>
          <li parentName="ul">{`Hydrauliska skivbromsar och dämpad framgaffel.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "köpguide-hur-väljer-du-den-perfekta-superior-elcykeln"
    }}>{`Köpguide: Hur Väljer Du Den Perfekta Superior Elcykeln?`}</h2>
    <p>{`Att välja den rätta elcykeln kan kännas överväldigande, men här är några tips för att hjälpa dig att bestämma vilken Superior elcykel som passar bäst för dig:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`För Stadspendling`}</strong>{`: Om du mestadels cyklar i stadsmiljö, rekommenderar vi cyklar från SSC-serien eller SBT-serien som är designade för urban mobilitet med elegant design och hög prestanda.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`För Längre Utflykter`}</strong>{`: Planerar du långdistanscykling? Då är EXR-serien något för dig. Dessa cyklar har kraftfulla motorer och lång räckvidd, vilket gör dem idealiska för längre turer.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`För Blandad Terräng`}</strong>{`: Om du cyklar både i staden och på landsbygden, är EXR 6050 B och SSC 160 L utmärkta val med sin robusta konstruktion och anpassningsförmåga.`}</p>
      </li>
    </ul>
    <p>{`Vi hoppas denna guide har hjälpt dig att hitta rätt Superior elcykel för dina behov. Välkommen att utforska våra cyklar och upplev skillnaden med Superior elcyklar idag!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      